const es = {
    header: {
        home: 'La casa',
        sports: 'Deportes',
        live_betting: 'Apuestas en vivo',
        login: 'Iniciar sesión',
        register: 'Registrarse ahora',
        balance: 'El saldo:',
    },
    profile_dropdown: {
        my_bets: 'Mis apuestas',
        my_sportsbook: 'Mi casa de apuestas',
        log_out: 'Cerrar sesión',
    },
    login: {
        title: 'Iniciar sesión en su cuenta',
        username: 'Nombre de usuario',
        password: 'Contraseña',
        sign_in: 'Iniciar sesión',
        error: {
            wrong_signatures: '¡Combinación incorrecta de nombre de usuario y contraseña!',
            something_went_wrong: 'Algo salió mal. Por favor, inténtelo de nuevo más tarde.',
        },
        remember_me: 'Acuérdate de mí',
        forgot_password: '¿Olvidaste tu contraseña?',
    },
    register: {
        title: 'Regístrate una cuenta',
        email: 'Correo electrónico',
        username: 'Nombre de usuario',
        first_name: 'Nombre de pila',
        last_name: 'Apellido',
        password: 'Contraseña',
        sign_up: 'Inscribirse'
    },
    footer: {
        all_rights_reserved: 'Reservados todos los derechos.',
    }
}
export default es
