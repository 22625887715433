const de = {
    header: {
        home: 'Heim',
        sports: 'Sport',
        live_betting: 'Live-Wetten',
        login: 'Einloggen',
        register: 'Anmelden',
        balance: 'Guthaben:',
    },
    profile_dropdown: {
        my_bets: 'Meine Wetten',
        my_sportsbook: 'Mein Sportwettenanbieter',
        log_out: 'Ausloggen',
    },
    login: {
        title: 'Melden Sie sich bei Ihrem Konto an',
        username: 'Nutzername',
        password: 'Passwort',
        sign_in: 'Anmelden',
        error: {
            wrong_signatures: 'Falsche Kombination aus Benutzername und Passwort!',
            something_went_wrong: 'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
        },
        remember_me: 'Erinnere dich an mich',
        forgot_password: 'Haben Sie Ihr Passwort vergessen?',
    },
    register: {
        title: 'Eröffnen Sie ein Konto',
        email: 'Email',
        username: 'Nutzername',
        first_name: 'Vorname',
        last_name: 'Nachname',
        password: 'Passwort',
        sign_up: 'Melden Sie sich an'
    },
    footer: {
        all_rights_reserved: 'Alle Rechte vorbehalten.',
    }
}
export default de
